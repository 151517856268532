import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'
// constant
import { THEME_COLOR } from '@/constants'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        secondary: THEME_COLOR,
        primary: '#18283b'
      },
      dark: {
        secondary: THEME_COLOR,
        primary: '#18283b'
      }
    }
  }
})
