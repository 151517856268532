import { isNil, keys, cloneDeep } from 'lodash'
import firebase from 'firebase/app'

import firestore from './async-firestore'

export default class GenericDB {
  defaultModel = null

  constructor(collectionPath) {
    this.collectionPath = collectionPath
  }

  /**
   * Return the default model inside each model
   */
  getDefaultModel() {
    return cloneDeep(this.defaultModel)
  }

  /**
   * Create a document in the collection
   * @param data
   * @param id
   */
  async create(data, id = null) {
    const collectionRef = (await firestore()).collection(this.collectionPath)
    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    const dataToCreate = {
      ...(this.getDefaultModel() || {}),
      ...data,
      createTimestamp: serverTimestamp,
      updateTimestamp: serverTimestamp
    }

    const createPromise = isNil(id)
      ? // Create doc with generated id
        collectionRef.add(dataToCreate).then(doc => doc.id)
      : // Create doc with custom id
        collectionRef
          .doc(id)
          .set(dataToCreate)
          .then(() => id)

    const docId = await createPromise

    return {
      id: docId,
      ...(this.getDefaultModel() || {}),
      ...data,
      createTimestamp: new Date(),
      updateTimestamp: new Date()
    }
  }

  /**
   * Read a document in the collection
   * @param id
   */
  async read(id) {
    const result = await (await firestore())
      .collection(this.collectionPath)
      .doc(id)
      .get()

    const data = result.exists ? result.data() : null

    if (isNil(data)) return null

    this.convertObjectTimestampPropertiesToDate(data)
    return { id, ...data }
  }

  /**
   * Read all documents in the collection following constraints
   *
   * @param {object} options - pager options
   */
  async readAll(options) {
    // Default pager options
    const defaultOptions = {
      constraints: null,
      limit: 20,
      next: null,
      order: {
        field: 'createTimestamp',
        direction: 'desc'
      },
      forceOrder: false,
      prev: null,
      query: null,
      queryField: null,
      typeSearch: 'like'
    }
    // Merge default options and user options
    const currentOptions =
      options === null
        ? defaultOptions
        : {
            ...defaultOptions,
            ...(options || {})
          }
    // Initial ref
    const collectionRef = (await firestore()).collection(this.collectionPath)
    // To set an orderBy
    const hasConstraintsOrQuery = Boolean(
      !isNil(currentOptions.query) || currentOptions.constraints
    )

    let refQuery = collectionRef

    if (currentOptions) {
      if (currentOptions.constraints) {
        currentOptions.constraints.forEach(constraint => {
          refQuery = refQuery.where(...constraint)
        })
      }

      // Order by (conditions group to set it)
      if (
        (!hasConstraintsOrQuery || currentOptions.forceOrder) &&
        currentOptions.order
      ) {
        refQuery = refQuery.orderBy(
          currentOptions.order.field,
          currentOptions.order.direction
        )
      }

      // Search
      if (!isNil(currentOptions.query)) {
        if (
          !currentOptions.typeSearch ||
          currentOptions.typeSearch === 'like'
        ) {
          // like
          refQuery = refQuery.orderBy(currentOptions.queryField, 'asc')
          refQuery = refQuery
            .startAt(currentOptions.query)
            .endAt(currentOptions.query.concat('\uf8ff'))
        } else {
          // exact
          refQuery = refQuery.where(
            currentOptions.queryField,
            '==',
            currentOptions.query
          )
        }
      } else if (currentOptions.next) {
        // Pagination
        refQuery = refQuery
          .startAfter(currentOptions.next[currentOptions.order.field])
          .limit(currentOptions.limit)
      } else if (currentOptions.prev) {
        // Pagination
        refQuery = refQuery
          .endBefore(currentOptions.prev[currentOptions.order.field])
          .limitToLast(currentOptions.limit)
      } else if (currentOptions.limit) {
        // Default
        refQuery = refQuery.limit(currentOptions.limit)
      }
    }

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      )

    return refQuery.get().then(formatResult)
  }

  /**
   * Update a document in the collection
   * @param data
   */
  async update(data) {
    const { id } = data
    const clonedData = cloneDeep(data)
    delete clonedData.id

    await (await firestore())
      .collection(this.collectionPath)
      .doc(id)
      .update({
        ...clonedData,
        updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      })

    return id
  }

  /**
   * Delete a document in the collection
   * @param id
   */
  async delete(id) {
    return (await firestore())
      .collection(this.collectionPath)
      .doc(id)
      .delete()
  }

  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  convertObjectTimestampPropertiesToDate(obj) {
    const newObj = {}

    keys(obj)
      .filter(prop => obj[prop] instanceof Object)
      .forEach(prop => {
        if (obj[prop] instanceof firebase.firestore.Timestamp) {
          newObj[prop] = obj[prop].toDate()
        }
        // Con las nuevas configuraciones genera error por exceso de llamadas
        // } else {
        //   this.convertObjectTimestampPropertiesToDate(obj[prop])
        // }
      })

    return {
      ...obj,
      ...newObj
    }
  }

  /**
   * Watch changes in a document
   * @param {string} id - UID document
   * @param {function} cb - callback function to trigger
   * @return {Promise | Boolean}
   */
  async watchChangesInDocument(id = null, cb = null) {
    if (id && typeof cb === 'function') {
      return (await firestore())
        .collection(this.collectionPath)
        .doc(id)
        .onSnapshot(cb)
    }
    return false
  }
}
