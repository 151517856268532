// Models
import PanelUser from '@/firebase/models/panelUser'

const panelUserDb = new PanelUser()

/**
 * Get panelUser by UID
 *
 * @param {string} id - UID panelUser in database
 * @return {object} - panel user data (promise)
 */
export async function getPanelUserById(id) {
  const panelUser = await panelUserDb.read(id)
  return panelUser
}

/**
 * Get all panel users
 *
 * @param {object} pagination - pagination options
 * @return {array} - panel user data (array/promise)
 */
export async function getAllPanelUsers(pagination) {
  const panelUsers = await panelUserDb.readAll(pagination)
  return panelUsers
}

/**
 * Create new panelUser in database
 *
 * @param {object} data - panelUser data
 * @return {object} - panel user data (promise)
 */
export async function createPanelUser(data, id = null) {
  // eslint-disable-next-line no-unused-vars
  const { email, password, ...params } = data
  // Creating user in DB
  const panelUser = await panelUserDb.create({ email, ...params }, id)

  return panelUser
}

/**
 * Update panelUser in database
 *
 * @param {object} data - panelUser data
 * @return {object} - panel user data (promise)
 */
export async function updatePanelUser(data) {
  const panelUser = await panelUserDb.update(data)
  return panelUser
}

/**
 * Watch changes in some document
 *
 * @param {string} id - UID document
 * @param {function} cb - function callback
 * @return {object} - panel user data (promise)
 */
export async function watchPanelUserById(id, cb) {
  if (id && typeof cb === 'function') {
    const watchFn = await panelUserDb.watchChangesInDocument(id, cb)
    return watchFn
  }
  return false
}
