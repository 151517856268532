<template>
  <div class="custom-dialog">
    <v-dialog
      :value="visible"
      v-bind="getDialogVOptions"
      @input="handleToggleDialog"
    >
      <v-card>
        <v-card-title v-if="!hideTitle">
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <component
              :is="getContentComponent"
              v-if="getContentComponent"
              v-bind="contentComponentProps"
              @onEventComponent="handleEventComponent"
            ></component>
            <template v-else>{{ contentText }}</template>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="!hideActionButtons">
          <FormButtons
            v-bind="getOptionsActionsButtons"
            @onClickAcceptButton="handleClickAcceptButton"
            @onClickCancelButton="handleClickCancelButton"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Components
import FormButtons from '@/components/ui/FormButtons'
// Vuex
import { mapState } from 'vuex'
// Utils
import { cloneDeep } from 'lodash'

export default {
  name: 'CustomDialog',
  components: { FormButtons },
  props: {
    contentText: {
      type: String,
      default: null
    },
    contentComponent: {
      type: Object,
      default() {
        return {}
      }
    },
    contentComponentProps: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/dialogs/#dialogs
     */
    dialogVOptions: {
      default() {
        return {
          'max-width': '600px',
          scrollable: true,
          transition: false,
          persistent: true
        }
      },
      type: Object
    },
    hideActionButtons: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    optionsActionsButtons: {
      type: Object,
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default: 'Título modal'
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Default option to dialog buttons
      defaultOptionsActionsButtons: {
        alignButtons: 'right', // left, center, right
        acceptButtonText: 'Aceptar',
        acceptButtonType: 'submit',
        acceptButtonClass: '',
        acceptButtonHide: false,
        acceptButtonLoading: false,
        acceptButtonDisabled: false,
        cancelButtonText: 'Cancelar',
        cancelButtonType: 'button',
        cancelButtonClass: '',
        cancelButtonHide: false,
        cancelButtonDisabled: false,
        defaultBehaviourButtons: true
      }
    }
  },
  computed: {
    ...mapState('app', ['isMobile']),
    /**
     * Get the component to use in Dialog
     */
    getContentComponent() {
      return cloneDeep(this.contentComponent)
    },
    /**
     * Get options (computed) to v-dialog
     */
    getDialogVOptions() {
      return this.isMobile
        ? { ...this.dialogVOptions, fullscreen: true }
        : this.dialogVOptions
    },
    /**
     * Get options (computed) to action buttons
     */
    getOptionsActionsButtons() {
      return {
        ...this.defaultOptionsActionsButtons,
        ...this.optionsActionsButtons
      }
    }
  },
  methods: {
    /**
     * Get the current state of dialog visibility
     *
     * @param {boolean} value - hide or show
     */
    handleToggleDialog(value) {
      this.$emit('onToggleDialog', value)
    },
    /**
     * Handle on click "Accept button"
     *
     * @param {object} event - event action
     */
    handleClickAcceptButton($event) {
      this.$emit('onClickAcceptButton', $event)
    },
    /**
     * Handle on click "Cancel button"
     *
     * @param {object} event - event action
     */
    handleClickCancelButton($event) {
      this.$emit('onClickCancelButton', $event)
    },
    /**
     * Handle event from component loaded
     *
     * @param {object} event - event action
     */
    handleEventComponent($event) {
      this.$emit('onEventComponent', $event)
    }
  }
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
.v-dialog {
  .v-card__title {
    background-color: $theme-color;
    .headline {
      color: #fff;
    }
  }
  .v-card__actions {
    background-color: $theme-background;
    padding: 16px 24px;
  }
}
@media (max-width: 768px) {
  .v-dialog {
    & > .v-card {
      & > .v-card__text {
        padding: 2rem 1.5rem !important;
        .container {
          height: 100%;
          position: relative;
          padding-bottom: 58px;
          .form-buttons {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: $theme-background;
            padding: 16px 24px;
          }
        }
      }
    }
  }
}
</style>
