<template>
  <v-app v-resize="handleResizeDebounce" class="app">
    <!-- Loading -->
    <AppLoadingLayer />
    <!-- Alert -->
    <AppAlert />
    <!-- Dialog -->
    <AppDialog />
    <!-- Content -->
    <router-view></router-view>
  </v-app>
</template>

<script>
// Components
import AppAlert from '@/components/ui/AppAlert'
import AppDialog from '@/components/elements/store/AppDialog'
import AppLoadingLayer from '@/components/ui/AppLoadingLayer'
// Mixins
import authMixin from '@/mixins/authMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapMutations, mapGetters, mapActions } from 'vuex'
// Utils
import { debounce } from 'lodash'

export default {
  name: 'App',
  components: { AppAlert, AppDialog, AppLoadingLayer },
  mixins: [authMixin, uiMixin],
  computed: {
    ...mapGetters('app', ['newContentAvailable'])
  },
  watch: {
    // Show alert about new content
    newContentAvailable(newValue) {
      if (newValue) {
        this.modifyAppAlert({
          actionButtonFn: () => {
            // Lanza el método de actualización
            this.serviceWorkerSkipWaiting()
          },
          actionButtonText: 'Actualizar',
          dismissibleTimeOut: -1,
          text: 'Hay nuevo contenido disponible',
          color: '#18283b',
          visible: true
        })
      }
    }
  },
  created() {
    // Show loading
    this.modifyAppLoadingLayer({ visible: true })
  },
  /**
   * LifeCycle Vue's functions
   */
  mounted() {
    // Hide loading
    this.modifyAppLoadingLayer({ visible: false })
  },
  methods: {
    ...mapActions('app', ['serviceWorkerSkipWaiting']),
    ...mapMutations('app', ['setIsMobile']),
    /**
     * Handle resize window (set if the device is a mobile)
     */
    handleResizeDebounce: debounce(function handleResize() {
      if (window.innerWidth < 768) {
        this.setIsMobile(true)
      } else {
        this.setIsMobile(false)
      }
    }, 250)
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.app {
  background-color: #fff;
}
</style>
