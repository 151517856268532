// Constans
import { URL_ACTIVATION_FORM, URL_LOGIN, URL_HOME, ROLES } from '@/constants'
// Mixins
import uiMixins from '@/mixins/uiMixin'
// Vuex
import { mapState } from 'vuex'
import { isNil } from 'lodash'
// Services
import { watchPanelUserById } from '@/services/panelUser'

export default {
  data() {
    return {
      watcher: null // To watch changes in document user panel
    }
  },
  computed: mapState('authentication', ['user']),
  mixins: [uiMixins],
  watch: {
    user: {
      handler(newValue) {
        const { location } = window
        // Remove watcher (unsubscribe)
        if (newValue === null && this.watcher !== null) {
          this.watcher()
        }

        if (newValue === undefined) return
        if (
          isNil(newValue) &&
          this.$route.name !== URL_LOGIN &&
          location.pathname !== `/${URL_ACTIVATION_FORM}`
        ) {
          this.routerPushTo(`/${URL_LOGIN}`) // Redirect to login
        } else if (!isNil(newValue) && this.$route.name === URL_LOGIN) {
          const urlHome =
            newValue.role &&
            ROLES[newValue.role] &&
            ROLES[newValue.role].urlHome
              ? ROLES[newValue.role].urlHome
              : URL_HOME

          this.routerPushTo(`/${urlHome}`) // Redirect home after register
          this.watchIfPanelUserIsActive(newValue.id)
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Watch if the panel user changes the attribute "active",
     * then we close the session
     *
     * @param {string} id - UID user
     */
    async watchIfPanelUserIsActive(id) {
      if (this.watcher === null) {
        this.watcher = await watchPanelUserById(id, querySnapshot => {
          const userData = querySnapshot.data()
          if (!userData.active) {
            this.closeSession()
          }
        })
      }
    }
  }
}
