import state from './companyActivations.state'
import mutations from './companyActivations.mutations'
import getters from './companyActivations.getters'

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
