// DB
export const PREFIX_COLLECTIONS = {
  horeca: 'horeca_',
  panel: 'panel_'
}
// ROUTES
export const URL_ACTIVATION_FORM = 'activation-form'
export const URL_HOME = 'kpi'
export const URL_LOGIN = 'login'
export const URL_LOGOUT = 'logout'
// THEMES
export const THEME_COLOR = '#7CBFDD'
// VUEX (persistance)
export const VUEX_PERSISTED_KEY = 'panel-schweppes'
// APP
export const APP_URL_BAKARTA = 'https://dashboard.bakarta.com'
export const CARTA_URL_BAKARTA = 'https://m.bakarta.com'
export const APP_URL_SCHWEPPES = 'https://dashboard.cartadigitalbyschweppes.com'
export const CARTA_URL_SCHWEPPES = 'https://cartadigitalbyschweppes.com'
export const ACTIVATION_TYPES = {
  assistant: {
    label: 'Activación Asistida',
    value: 'assistant'
  },
  noActivation: {
    label: 'Sin activación',
    value: 'no'
  },
  noEmail: {
    label: 'Email en uso',
    value: 'noEmail'
  },
  noPhoto: {
    label: 'Sin fotos',
    value: 'noPhoto'
  },
  self: {
    label: 'Autoactivación',
    value: 'self'
  },
  rejected: {
    label: 'En revisión',
    value: 'rejected'
  },
  support: {
    label: 'Soporte',
    value: 'support'
  }
}
export const ACTIVATION_STATUS = {
  done: {
    label: 'Hecho',
    value: 'done'
  },
  preview: {
    label: 'Vista previa',
    value: 'preview'
  },
  inProcess: {
    label: 'En proceso',
    value: 'inProcess'
  },
  pending: {
    label: 'Pendiente',
    value: 'pending'
  }
}
export const DEFAULT_PASSWORD = 'cartadigitalbyschweppes'
export const DEFAULT_SUBSCRIPTION_PLAN = 'pro'
export const ROLES = {
  admin: {
    label: 'Administrador',
    urlHome: 'statistics',
    value: 'admin'
  },
  activator: {
    label: 'Activadores',
    urlHome: 'company-activations',
    value: 'activator'
  },
  commercial: {
    label: 'Comercial',
    urlHome: 'company-keys',
    value: 'commercial'
  },
  provider: {
    label: 'Proveedor',
    urlHome: 'stickers-orders',
    value: 'provider'
  }
}
export const STICKERS_STATUS = {
  accepted: {
    label: 'Aceptado',
    value: 'accepted'
  },
  cancelled: {
    label: 'Cancelado',
    value: 'cancelled'
  },
  pending: {
    label: 'Pendiente',
    value: 'pending'
  },
  print: {
    label: 'Impreso',
    value: 'print'
  },
  sent: {
    label: 'Enviado',
    value: 'sent'
  }
}
export const TRADER_AREAS = ['1', '2', '3', '4', '6']
// Gerencias por Área
export const MANAGEMENTS = {
  '1': ['102', '103', '106', '108', '110'],
  '2': ['202', '204', '205', '206', '207', '208', '304'],
  '3': ['301', '306', '310', '501', '504', '505'],
  '4': ['401', '403', '404', '405', '406', '407', '603'],
  '6': ['601', '602', '604', '605', '608', '701', '703']
}
