export default {
  appTitle: process.env.VUE_APP_TITLE,
  appAlert: {
    actionButtonFn: null,
    actionButtonText: 'Accept',
    dismissibleTimeOut: 5000,
    type: 'info', // success, info, warning, and error.
    text: null,
    visible: false
  },
  appDialog: {
    acceptButtonFn: null,
    cancelButtonFn: null,
    contentText: null,
    contentComponent: null,
    contentComponentProps: null,
    hideActionButtons: false,
    hideTitle: false,
    optionsActionsButtons: {
      alignButtons: 'right', // left, center, right
      acceptButtonText: 'Aceptar',
      acceptButtonType: 'submit',
      acceptButtonClass: '',
      acceptButtonHide: false,
      acceptButtonLoading: false,
      acceptButtonDisabled: false,
      cancelButtonText: 'Cancelar',
      cancelButtonType: 'button',
      cancelButtonClass: '',
      cancelButtonHide: false,
      cancelButtonDisabled: false,
      defaultBehaviourButtons: true
    },
    title: 'Titulo dialog',
    visible: false
  },
  appLoadingLayer: {
    text: null,
    visible: false
  },
  isMobile: false,
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  refreshingApp: false
}
