import firebase from 'firebase/app'
import { isNil } from 'lodash'

import store from '@/store'

firebase.auth().onAuthStateChanged(firebaseUser => {
  if (isNil(firebaseUser)) {
    store.dispatch('authentication/logout', firebaseUser)
  }
})
