/**
 * This import way the components is to build differents
 * chunks and will do the lighter app
 * */
// Layouts
const HeaderMain = () => import('@/views/layout/HeaderMain')
// Views
const Login = () => import('@/views/authentication/Login')
const RecoverPassword = () => import('@/views/authentication/RecoverPassword')
const AuthenticationForm = () =>
  import('@/views/authentication/AuthenticationForm')
const ReportsList = () => import('@/views/reports/ReportsList')
const PanelUsersList = () => import('@/views/panelUsers/PanelUsersList')
const PanelUsersForm = () => import('@/views/panelUsers/PanelUsersForm')
const CompanyActivationsList = () =>
  import('@/views/companyActivations/CompanyActivationsList')
const CompanyActivationsForm = () =>
  import('@/views/companyActivations/CompanyActivationsForm')
const CompanyActivationsFormMenu = () =>
  import('@/views/companyActivations/CompanyActivationsFormMenu')
const CompanyKeysList = () => import('@/views/companyKeys/CompanyKeysList')
const CompanyKeysForm = () => import('@/views/companyKeys/CompanyKeysForm')
const CompanyKeysImportForm = () =>
  import('@/views/companyKeys/CompanyKeysImportForm')
const StickersOrdersList = () => import('@/views/stickers/StickersOrdersList')
const StickersOrdersForm = () => import('@/views/stickers/StickersOrdersForm')
const StickersOrdersNew = () => import('@/views/stickers/StickersOrdersNew')
const ActivationForm = () => import('@/views/companyActivations/ActivationForm')
// const ActivationFormDisabled = () =>
//   import('@/views/companyActivations/ActivationFormDisabled')

export default [
  {
    path: '/',
    component: HeaderMain,
    children: [
      // KPI
      {
        path: '/',
        name: 'reports',
        component: ReportsList,
        meta: {
          roles: ['admin', 'commercial']
        }
      },
      // Panel users
      {
        path: '/panel-users',
        name: 'panel-users',
        component: PanelUsersList,
        meta: {
          roles: ['admin']
        }
      },
      {
        path: '/panel-users-form/:id?',
        name: 'panel-users-form',
        component: PanelUsersForm,
        meta: {
          roles: ['admin']
        }
      },
      // Company keys
      {
        path: '/company-keys',
        name: 'company-keys',
        component: CompanyKeysList,
        meta: {
          roles: ['admin', 'commercial']
        }
      },
      {
        path: '/company-keys-form/:id?',
        name: 'company-keys-form',
        component: CompanyKeysForm,
        meta: {
          roles: ['admin', 'commercial']
        }
      },
      {
        path: '/company-keys-import-form',
        name: 'company-keys-import-form',
        component: CompanyKeysImportForm,
        meta: {
          roles: ['admin']
        }
      },
      // Company activations
      {
        path: '/company-activations',
        name: 'company-activations',
        component: CompanyActivationsList,
        meta: {
          roles: ['admin', 'activator']
        }
      },
      {
        path: '/company-activations-form/:id',
        name: 'company-activations-form',
        component: CompanyActivationsForm,
        meta: {
          roles: ['admin']
        }
      },
      {
        path: '/company-activations-form-menu/:id',
        name: 'company-activations-form-menu',
        component: CompanyActivationsFormMenu,
        meta: {
          roles: ['admin', 'activator']
        }
      },
      // Panel Stickers
      {
        path: '/stickers-orders',
        name: 'stickers-orders',
        component: StickersOrdersList,
        meta: {
          roles: ['admin', 'provider', 'commercial']
        }
      },
      {
        path: '/stickers-orders-form/:id?',
        name: 'stickers-orders-form',
        component: StickersOrdersForm,
        meta: {
          roles: ['admin', 'provider', 'commercial']
        }
      },
      {
        path: '/stickers-orders-new',
        name: 'stickers-orders-new',
        component: StickersOrdersNew,
        meta: {
          roles: ['admin', 'provider', 'commercial']
        }
      },
      // Auth
      {
        path: '/authentication-form',
        name: 'authentication-form',
        component: AuthenticationForm,
        meta: {
          roles: ['admin', 'activator', 'commercial', 'provider']
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: RecoverPassword,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/activation-form',
    name: 'activation-form',
    component: ActivationForm,
    meta: {
      isPublic: true
    }
  },
  { path: '*', redirect: '/' }
]
