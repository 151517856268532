import Vue from 'vue'
import Router from 'vue-router'
// Constants
import { URL_LOGIN } from '@/constants'
// Vuex
import store from '@/store'
// Utils
import { get } from 'lodash'
// Routes
import routesName from './routesName'

Vue.use(Router)

const routes = routesName

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior() {
    // Always on top when change
    // the path into the app
    return { x: 0, y: 0 }
  }
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  const user = get(store, 'state.authentication.user', false)
  const isPublic = Boolean(to.meta && to.meta.isPublic)
  const isUrlLogin = to.name === URL_LOGIN
  const hasRightRole = Boolean(
    to.meta && to.meta.roles && to.meta.roles.indexOf(user.role) > -1
  )

  if (!isPublic && !hasRightRole && !isUrlLogin) {
    next({ path: `/${URL_LOGIN}` })
  } else {
    next()
  }
})

export default router
