import {
  signInWithEmailAndPassword,
  updateLoggedUser,
  updatePassword,
  signOutUser
} from '@/services/authentication'
import { updatePanelUser, getPanelUserById } from '@/services/panelUser'

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit }, { email, password }) => {
    // Login user
    const userLogged = await signInWithEmailAndPassword(email, password)
    // Get data from Database and set in Store
    const user = await getPanelUserById(userLogged.user.uid)

    // Comprueba si el usuario y activo se está logando en la
    // aplicación correcta. Solo se permite, en caso de que
    // no exista la variable "brand" en el usuario, logar en "bakarta"
    if (
      !user.brand ||
      (user.brand && user.brand !== process.env.VUE_APP_BRAND) ||
      !user.active
    ) {
      await signOutUser()
      throw new Error(
        'El usuario indicado no pertenece a esta plataforma o está inactivo'
      )
    }

    // Set in store
    commit('setUser', user)
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    // Reset locale store
    commit('resetUser')
  },

  /**
   * Update user authentication (firebase authentication)
   */
  updateUserAuth: async (
    { commit, state },
    { name, password, changePassword }
  ) => {
    // Update password?
    if (changePassword) {
      await updatePassword(password)
    }
    // Update user data (authentication)
    await updateLoggedUser({
      displayName: name
    })
    // Update user data (panelUsers DB)
    await updatePanelUser({
      id: state.user.id,
      name
    })
    // Set in store
    commit('setUser', { name })
  }
}
