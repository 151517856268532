import defaultState from './app.defaultState'

const { appAlert, appDialog, appLoadingLayer } = defaultState

export default {
  setIsMobile: (state, value) => (state.isMobile = value),
  setNetworkOnline: (state, value) => (state.networkOnLine = value),
  setSWRegistrationForNewContent: (state, value) =>
    (state.SWRegistrationForNewContent = value),
  setRefreshingApp: (state, value) => (state.refreshingApp = value),
  // App alert
  setAppAlert: (state, value) => (state.appAlert = { ...appAlert, ...value }),
  resetAppAlert: state => (state.appAlert = appAlert),
  // App dialog
  setAppDialog: (state, value) =>
    (state.appDialog = { ...appDialog, ...value }),
  // App loading
  resetAppDialog: state => (state.appDialog = appDialog),
  setAppLoadingLayer: (state, value) =>
    (state.appLoadingLayer = { ...appLoadingLayer, ...value }),
  resetAppLoadingLayer: state => (state.appLoadingLayer = appLoadingLayer),
  setAppSearches: (state, value) => (state.appSearches = value)
}
