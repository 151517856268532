import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Utils Bento-starter
import '@/utils/register-service-worker'
import '@/utils/handle-network-status'
import 'pwacompat'
// Firebase
import '@/firebase/init'
import '@/firebase/auth/authentication'
// Framework CSS
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
