<template>
  <div class="app-alert">
    <v-alert
      v-bind="alertVOptions"
      :type="appAlert.type"
      :value="appAlert.visible"
      @input="handleDismissible"
    >
      <template v-if="appAlert.actionButtonFn">
        <v-row align="center">
          <v-col class="grow">{{ appAlert.text }}</v-col>
          <v-col class="shrink">
            <v-btn @click="handleClick">{{ appAlert.actionButtonText }}</v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        {{ appAlert.text }}
      </template>
    </v-alert>
  </div>
</template>

<script>
// Vuex
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'AppAlert',
  props: {
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/alerts/#alerts
     */
    alertVOptions: {
      default() {
        return {
          icon: false,
          dismissible: true,
          prominent: true,
          transition: ''
        }
      },
      type: Object
    }
  },
  computed: {
    ...mapState('app', ['appAlert'])
  },
  watch: {
    // After N seconds alert will disapear
    appAlert(newValue) {
      if (newValue.visible && newValue.dismissibleTimeOut > 0) {
        setTimeout(() => {
          if (typeof this.resetAppAlert === 'function') {
            this.resetAppAlert()
          }
        }, newValue.dismissibleTimeOut)
      }
    }
  },
  methods: {
    ...mapMutations('app', ['resetAppAlert']),
    /**
     * Change the value (visible) to show or hide alert
     *
     * @param {boolean} value - hide or show
     */
    handleDismissible(value) {
      if (!value) this.resetAppAlert()
    },
    /**
     * Click on the action button
     */
    handleClick() {
      if (typeof this.appAlert.actionButtonFn === 'function') {
        this.appAlert.actionButtonFn()
      }
      // reset alert
      this.resetAppAlert()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.app-alert {
  position: fixed;
  z-index: 250; // por encima de appDialog pero por debajo de appLoading
  width: 60%;
  top: 3%;
  left: 50%;
  margin-left: -30%;
}
@media (max-width: 768px) {
  .app-alert {
    top: auto;
    bottom: 0;
    left: 0;
    margin-left: 0;
    width: 100%;
    & > .v-alert {
      border-radius: 0;
      margin-bottom: 0;
    }
  }
}
</style>
