import firebase from 'firebase/app'
// Contans
import { APP_URL_BAKARTA, APP_URL_SCHWEPPES } from '@/constants'
// Utils
import { getCurrentBrand } from '@/utils'

/**
 * Register user in firebare with email and password
 *
 * @param {string} email - email's user
 * @param {string} password - password's user
 * @param {object} data - other options' user
 * @return {promise}
 */
export async function createUserWithEmailAndPassword(email, password, data) {
  const user = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)

  if (user && data) {
    await firebase.auth().currentUser.updateProfile(data)
  }
  return user
}

/**
 * update data current user
 *
 * @param {object} password - password's user
 * @return {promise}
 */
export async function updatePassword(password) {
  await firebase.auth().currentUser.updatePassword(password)
}

/**
 * Change password to current user
 *
 * @param {object} data - user data
 * @return {promise}
 */
export async function updateLoggedUser(data) {
  await firebase.auth().currentUser.updateProfile(data)
}

/**
 * Login user in firebase with email and password
 *
 * @param {string} email - email's user
 * @param {string} password - password's user
 * @return {promise}
 */
export async function signInWithEmailAndPassword(email, password) {
  const user = await firebase.auth().signInWithEmailAndPassword(email, password)
  return user
}

/**
 * SignOut user from firebase
 */
export async function signOutUser() {
  await firebase.auth().signOut()
}

/**
 * Delete User Auth
 */
export async function deleteUserAuth() {
  const user = firebase.auth().currentUser
  if (user) await firebase.auth().currentUser.delete()
}

/**
 * Envia un mensaje al email para recuperar la contraseña
 *
 * @param {string} email - user email
 * @return {promise}
 */
export async function recoverPassword(email) {
  const brand = getCurrentBrand(process.env.VUE_APP_BRAND)
  const appUrl = brand === 'bakarta' ? APP_URL_BAKARTA : APP_URL_SCHWEPPES
  await firebase.auth().sendPasswordResetEmail(email, {
    url: appUrl,
    handleCodeInApp: false
  })
}
