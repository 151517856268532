// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import GenericDB from '../generic-db'

export default class PanelUser extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    active: true,
    brand: process.env.VUE_APP_BRAND || 'bakarta',
    email: null,
    name: null,
    phone: null,
    role: 'admin' // 'admin', 'activator', 'commercial', 'provider'
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.panel}users`)
  }
}
