<template>
  <transition name="fade">
    <div v-show="appLoadingLayer.visible" class="app-loading-layer">
      <v-progress-circular v-bind="loadingVOptions">
        <v-img :src="getBrandImage" class="brand" />
      </v-progress-circular>
    </div>
  </transition>
</template>

<script>
// Utils
import { getPathImage } from '@/utils'
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'AppLoadingLayer',
  props: {
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/progress-circular/#progress-circular
     */
    loadingVOptions: {
      default() {
        return {
          size: '100',
          width: '4',
          indeterminate: true,
          color: 'white'
        }
      },
      type: Object
    }
  },
  computed: {
    ...mapState('app', ['appLoadingLayer']),

    /**
     * Return brand image
     *
     * @return {string} - brand image
     */
    getBrandImage() {
      return getPathImage('brand-loading.png', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.app-loading-layer {
  position: fixed;
  z-index: 300; // por encima de appDialog y appAlert
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $theme-color;
  .brand {
    width: 40px;
  }
}
</style>
