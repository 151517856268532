// Services
import { signOutUser } from '@/services/authentication'

export default {
  methods: {
    /**
     * Clean every session user data
     */
    closeSession() {
      signOutUser()
    },

    /**
     * Modify app alert layer
     *
     * @param {object} params - alert options
     */
    modifyAppAlert(params) {
      this.$store.commit('app/setAppAlert', params)
    },

    /**
     * Modify app dialog
     *
     * @param {object} params - dialog options
     */
    modifyAppDialog(params) {
      this.$store.commit('app/setAppDialog', params)
    },

    /**
     * Modify app loading layer
     *
     * @param {object} params - loading layer options
     */
    modifyAppLoadingLayer(params) {
      this.$store.commit('app/setAppLoadingLayer', params)
    },

    /**
     * Move us to differents position into nav history
     *
     * @param {number} steps - "steps" number that we want
     * move the history to forward (positive value)
     * or back (negative value)
     */
    routerGoTo(steps = -1) {
      this.$router.go(steps)
    },

    /**
     * We navigate to ...
     *
     * @param {object} data - https://router.vuejs.org/guide/essentials/navigation.html
     */
    routerPushTo(data) {
      this.$router.push(data)
    }
  }
}
