<template>
  <div :class="`${getAlignButtons} form-buttons`">
    <!-- Delete button -->
    <v-btn
      v-if="!deleteButtonHide"
      class="form-button"
      data-cy="form-button-delete"
      v-bind="deleteButtonVOptions"
      :disabled="deleteButtonDisabled || disabledAllButtons"
      :class="deleteButtonClass"
      :type="deleteButtonType"
      @click="handleClickDeleteButton($event)"
      >{{ deleteButtonText }}
    </v-btn>
    <!-- Cancel button -->
    <v-btn
      v-if="!cancelButtonHide"
      class="form-button"
      data-cy="form-button-cancel"
      v-bind="cancelButtonVOptions"
      :disabled="cancelButtonDisabled || disabledAllButtons"
      :class="cancelButtonClass"
      :type="cancelButtonType"
      @click="handleClickCancelButton($event)"
      >{{ cancelButtonText }}
    </v-btn>
    <!-- Accept button -->
    <v-btn
      v-if="!acceptButtonHide"
      class="form-button"
      data-cy="form-button-accept"
      v-bind="acceptButtonVOptions"
      :loading="acceptButtonLoading"
      :disabled="acceptButtonDisabled || disabledAllButtons"
      :class="acceptButtonClass"
      :type="acceptButtonType"
      @click="handleClickAcceptButton($event)"
      >{{ acceptButtonText }}
    </v-btn>
  </div>
</template>

<script>
// Vuex
import { mapState } from 'vuex'

export default {
  name: 'FormButtons',
  props: {
    alignButtons: {
      default: 'right', // left, center, right
      type: String
    },
    acceptButtonText: {
      default: 'Enviar',
      type: String
    },
    acceptButtonType: {
      default: 'submit',
      type: String
    },
    acceptButtonClass: {
      default: '',
      type: String
    },
    acceptButtonHide: {
      default: false,
      type: Boolean
    },
    acceptButtonLoading: {
      default: false,
      type: Boolean
    },
    acceptButtonDisabled: {
      default: false,
      type: Boolean
    },
    cancelButtonText: {
      default: 'Cancelar',
      type: String
    },
    cancelButtonType: {
      default: 'button',
      type: String
    },
    cancelButtonClass: {
      default: '',
      type: String
    },
    cancelButtonHide: {
      default: false,
      type: Boolean
    },
    cancelButtonDisabled: {
      default: false,
      type: Boolean
    },
    deleteButtonText: {
      default: 'Eliminar',
      type: String
    },
    deleteButtonType: {
      default: 'button',
      type: String
    },
    deleteButtonClass: {
      default: '',
      type: String
    },
    deleteButtonHide: {
      default: true,
      type: Boolean
    },
    deleteButtonDisabled: {
      default: false,
      type: Boolean
    },
    defaultBehaviourButtons: {
      default: true,
      type: Boolean
    },
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions: {
      default() {
        return {
          color: 'primary',
          elevation: 0
        }
      },
      type: Object
    },
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    cancelButtonVOptions: {
      default() {
        return {
          color: 'normal',
          elevation: 0,
          text: true
        }
      },
      type: Object
    },
    /**
     * Debe ser pasado desde el padre con una variable computada
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    deleteButtonVOptions: {
      default() {
        return {
          color: 'error',
          elevation: 0,
          text: true
        }
      },
      type: Object
    }
  },
  computed: {
    ...mapState('app', ['isMobile']),
    /**
     * Disabled all button when accept button is "loading"
     */
    disabledAllButtons() {
      return this.acceptButtonLoading && this.defaultBehaviourButtons
    },
    /**
     * Get current position (class CSS) for align buttons
     */
    getAlignButtons() {
      return this.isMobile ? 'mobile' : this.alignButtons
    }
  },
  methods: {
    /**
     * Return event on click delete button
     *
     * @return {object} - event object
     */
    handleClickDeleteButton($event) {
      this.$emit('onClickDeleteButton', $event)
    },
    /**
     * Return event on click accept button
     *
     * @return {object} - event object
     */
    handleClickAcceptButton($event) {
      this.$emit('onClickAcceptButton', $event)
    },
    /**
     * Return event on click accept button
     *
     * @return {object} - event object
     */
    handleClickCancelButton($event) {
      this.$emit('onClickCancelButton', $event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.form-buttons {
  display: flex;
  flex-direction: row;
  flex: 1;
  .form-button {
    margin: 0 0.25rem;
  }
  &.left {
    justify-content: flex-start;
    .form-button:first-child {
      margin-left: 0;
    }
  }
  &.right {
    justify-content: flex-end;
    .form-button:last-child {
      margin-right: 0;
    }
  }
  &.center {
    justify-content: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.mobile {
    justify-content: center;
    .form-button {
      flex: 1;
    }
  }
}
</style>
