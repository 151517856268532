import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// contants
import { VUEX_PERSISTED_KEY } from '@/constants'
// Vuex modules
import authentication from './authentication'
import app from './app'
import companyActivations from './companyActivations'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key: VUEX_PERSISTED_KEY,
      paths: [
        'authentication.user',
        'app.appSearches',
        'companyActivations.commercial'
      ]
    })
  ],
  modules: {
    authentication,
    app,
    companyActivations
  }
})
