import { isNil } from 'lodash'

/**
 * Devuelve el valor de la variable "brand"
 *
 * @param {string} brand
 * @return {string}
 */
export function getCurrentBrand(brand) {
  return isNil(brand) ? 'bakarta' : brand
}

/**
 * Return public image path
 *
 * @param {string} image - image name
 * @param {boolean} prefixBrand - put the brand prefix
 * @return {string}
 */
export function getPathImage(image = null, prefixBrand = false) {
  const prefix = prefixBrand ? `/${process.env.VUE_APP_BRAND}` : ''
  return image ? `/img${prefix}/${image}` : ''
}

/**
 * Base64 to Blob (file)
 *
 * @param {string} dataURI - file object
 */
export function dataURItoBlob(dataURI) {
  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])
  const mime = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  const max = bytes.length
  const ia = new Uint8Array(max)

  for (let i = 0; i < max; i += 1) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ia], { type: mime })
}

/**
 * Read file to text (file)
 *
 * @param {File} file - file object
 * @param {function} success - function success
 * @param {function} error - function success*
 * @return {string}
 */
export async function fileAsText(file, success = null, error = null) {
  if (window.FileReader) {
    const reader = new FileReader()
    // Read file into memory as UTF-8
    reader.readAsText(file)
    // Handle errors load
    reader.onload = success
    reader.onerror = error
  } else {
    console.error('FileReader are not supported in this browser.')
  }
}

/**
 * Get data from CSV data
 *
 * @param {string} csv - content csv file
 * @param {object} options - options to parse CSV
 * @return {array} - data array
 */
export function getDataFromCSV(
  csv,
  { split, hasHeader } = { split: ';', hasHeader: false }
) {
  const allTextLines = csv.split(/\r\n|\n/)
  const lines = []

  for (let i = 0; i < allTextLines.length; i += 1) {
    if (allTextLines[i] && (!hasHeader || i > 0)) {
      const data = allTextLines[i].split(split)
      const tarr = []
      for (let j = 0; j < data.length; j += 1) {
        tarr.push(data[j])
      }
      lines.push(tarr)
    }
  }
  return lines
}

/**
 * ¿Se trata de un carta procedente de Bakarta?
 *
 * @param {string} url - place url
 * @return {boolean}
 */
export function isBakartaDomain(url) {
  if (!url || typeof url !== 'string') return false

  const domain = url
    .replace('http://', '')
    .replace('https://', '')
    .split(/[/?#]/)[0]

  return domain === 'm.bakarta.com'
}
